// @flow
import * as React from 'react';
import classNames from 'classnames';
// import { Icon } from 'client/components/common';
import { connect } from 'react-redux';
import { Header } from 'client/components/common';
import { createItem } from 'client/actions';
import { COLLECTIONS } from 'client/constants/common';
import { LEAD_CONSTANTS } from 'common/constants';
import Div100vh from 'react-div-100vh';
import * as css from './Intro.scss';
import FormScreen from './FormScreen';
import LeadCreatedScreen from './LeadCreatedScreen';
import ThanksScreen from './ThanksScreen';
import ClosedActivityScreen from './ClosedActivityScreen';

type Props = {
	createItem: typeof createItem,
};

type State = {
	formScreen: boolean,
	thanksScreen: boolean,
	leadCreatedScreen: boolean,
	closedActivityScreen: boolean,
};

class Intro extends React.PureComponent<Props, State> {
	state = {
		formScreen: true,
		thanksScreen: false,
		leadCreatedScreen: false,
		closedActivityScreen: false,
	};

	componentDidMount() {
		const isCreatedLead = localStorage.getItem('ynetOsemFormLeadCreated');
		if (isCreatedLead) {
			this.setState({
				formScreen: false,
				thanksScreen: true,
			});
		}
	}

	onCreateLead = async data => {
		const result = await this.props.createItem<any>({ collection: COLLECTIONS.LEADS, data });
		if (result.lead === LEAD_CONSTANTS.FOUNDED) {
			this.setState({
				formScreen: false,
				leadCreatedScreen: true,
			});

			localStorage.setItem('ynetOsemFormLeadCreated', 'true');
		}

		if (result.lead === LEAD_CONSTANTS.CREATED) {
			this.setState({
				formScreen: false,
				leadCreatedScreen: true,
			});
			localStorage.setItem('ynetOsemFormLeadCreated', 'true');
		}
	};

	render() {
		const { formScreen, leadCreatedScreen, thanksScreen, closedActivityScreen } = this.state;
		return (
			<Div100vh>
				<div className={css.intro}>
					<Header />
					{!leadCreatedScreen && !thanksScreen && !closedActivityScreen && formScreen && (
						<FormScreen onCreateLead={this.onCreateLead} className={classNames(formScreen && css.active)} />
					)}
					{leadCreatedScreen && <LeadCreatedScreen className={classNames(leadCreatedScreen && css.active)} />}
					{thanksScreen && <ThanksScreen className={classNames(thanksScreen && css.active)} />}
					{closedActivityScreen && (
						<ClosedActivityScreen className={classNames(closedActivityScreen && css.active)} />
					)}
				</div>
			</Div100vh>
		);
	}
}

const mapState = (state: ClientStore) => ({});
const mapDispatch = {
	createItem,
};

export default connect(mapState, mapDispatch)(Intro);
