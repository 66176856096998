/* eslint-disable max-len */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import { Responsive } from 'client/components/common';

import leadCreatedScreenBg from 'assets/client/images/bg.jpg';
import leadCreatedScreenBgMobile from 'assets/client/images/bg-mobile.png';
import logo1 from 'assets/client/images/logo-1.png';

import * as css from './Intro.scss';

type Props = {
	className?: string,
};

const ClosedActivityScreen = ({ className }: Props) => {
	const isTabletL = Responsive.isMatching(Responsive.TABLET_L);
	const isTabletP = Responsive.isMatching(Responsive.TABLET_P);
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const isMobile = Responsive.isMatching(Responsive.MOBILE);

	const bg = isDesktop || isTabletL || isTabletP ? leadCreatedScreenBg : leadCreatedScreenBgMobile;
	const subtitleText =
		'החופש הגדול הוא הזדמנות מצוינת לפעילויות משפחתיות מהנות שמחזקות את החיבור בין הורים וילדים ומאפשרות שיח מקרב ומלמד.';
	const subtitleText2 = (
		<>
			במסגרת פרויקט ״מתכון לחיים טובים״, ynet ואסם-נסטלה <br />
			מזמינים אתכם לשתף במתכון המשפחתי המאוזן שלכם לקיץ מרענן וקריר, ואולי תזכו במארז מוצרים של אסם-נסטלה
			<span> בשווי 300 שקלים</span> - כדי שתוכלו להמשיך לבשל ביחד.
		</>
	);

	const subtitleText2Mobile = (
		<>
			במסגרת פרויקט ״מתכון לחיים טובים״, ynet ואסם-נסטלה מזמינים אתכם לשתף במתכון המשפחתי המאוזן שלכם לקיץ מרענן
			וקריר, ואולי תזכו במארז מוצרים של אסם-נסטלה <span> בשווי 300 שקלים</span> - כדי שתוכלו <br />
			להמשיך לבשל ביחד.
		</>
	);
	return (
		<div
			className={classNames(css.leadCreatedScreen, isMobile && css.bgGradient, className)}
			style={{ backgroundImage: `url(${bg})` }}
		>
			<div className={css.titleBlock}>
				<img src={logo1} alt="" className={css.logo} />
				<h1 className={css.title}>תמיד שמח במטבח שלנו</h1>
				<p className={css.subtitleTextBold}>{subtitleText}</p>
				<p className={css.subtitleText}>{isMobile ? subtitleText2Mobile : subtitleText2}</p>
			</div>
			<h4 className={css.title2}>ההרשמה הסתיימה</h4>
		</div>
	);
};

ClosedActivityScreen.defaultProps = {
	className: '',
};

export default ClosedActivityScreen;
